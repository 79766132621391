@import url(http://fonts.cdnfonts.com/css/alleyster);
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.headerX {
  background-image: url(//odoocdn.com/openerp_website/static/src/img/2020/website/owb_top_shapes.svg);
  background-repeat: repeat;
  background-size: 520px auto;
  background-position: 0% 100%;
}

.headerX2{
  background-image: url(https://i.giphy.com/media/3o7WIx7urV838kHFzW/giphy.webp);
  background-repeat: no-repeat;
  background-size: 250px auto;
  background-position: 0% 100%;
}

.headerX3{
  background-image: url(https://c.tenor.com/NKbJxDmA_gMAAAAi/coffee-coffee-time.gif);
  background-repeat: no-repeat;
  background-size: 250px auto;
  background-position: 45% 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

